import http from "@/core/requests/abstract-request";

export function getAll() {
  return http('get',    '/admin/posts');
}
export function getOne(id) {
  return http('get',    `/admin/posts/${id}`);
}
export function create(post) {
  return http('post',   '/admin/posts', post);
}
export function update(id, post) {
  return http('put',    `/admin/posts/${id}`, post);
}
export function remove(id) {
  return http('delete', `/admin/posts/${id}`);
}