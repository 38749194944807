<template>
  <div class="posts-section">
    <div class="cntr cntr-wide">
      <h1>Lista postów</h1>
      <button @click="addPost" class="btn btn-primary">Dodaj nowy</button>
      <table class="table" v-if="posts.length > 0">
        <thead>
          <tr>
            <th>Data</th>
            <th>Tytuł</th>
            <th class="sr-text">Język i status</th>
            <th class="sr-text">Akcje</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(post, index) in posts" :key="post.id">
            <td>{{ post.createdAt }}</td>
            <td>{{ post.title }}</td>
            <td>
              <div class="flex">
                <svg class="icc icc-flag">
                  <use
                    :href="require('@/assets/langs.defs.svg') + '#' + post.lang"
                  ></use>
                </svg>
                <svg
                  class="icc icc-status"
                  v-bind:class="{
                    'iconx-active': post.status == 1,
                    'iconx-hidden': post.status == 2,
                  }"
                >
                  <use href="../assets/feather-sprite.svg#link"></use>
                </svg>
              </div>
            </td>
            <td class="actions">
              <button class="btn" @click="editPost(post)">Edytuj</button>
              <button class="btn" @click="duplicatePost(post.id)">Duplikuj</button>
              <button class="btn" @click="deletePost(post.id, index)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="message" v-if="requestMessage != ''">
        {{ requestMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { getAll, remove } from "../core/requests/authed-request";

export default {
  name: "Posts",
  data() {
    return {
      posts: [],
      requestMessage: "Trwa pobieranie wpisów…",
    };
  },
  methods: {
    fetchPosts() {
      getAll()
        .then((resp) => {
          const posts = resp.data;
          this.requestMessage = "";
          this.posts = posts;
        })
        .catch((e) => {
          console.log(e);
          this.requestMessage = "Wystąpił błąd podczas pobierania wpisów";
        });
    },
    addPost() {
      this.$router.replace({
        name: "AddPost",
      });
    },
    duplicatePost(id) {
      console.log('Duplicate post action - id: ' + id);
      this.$router.replace({
        name: "AddPost",
        params: {
          id,
        },
      });
    },
    editPost(post) {
      this.$router.replace({
        name: "PostDetail",
        params: {
          id: post.id,
        },
      });
    },
    deletePost(id, index) {
      if (confirm("Czy na pewno usunąć ten post?")) {
        remove(id).then((resp) => {
          // console.log(this.posts.length, resp);
          if (resp.statusCode == 200) {
            this.$delete(this.posts, index);
            // console.log(this.posts.length);
          }
        });
      }
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>

<style lang="scss">
.icc-flag {
  stroke: initial;
  fill: black;
  border: 2px solid black;
  padding: 0;
  // margin: 8px;
  // width: 36px;
  // height: 36px;
  width: 30px;
  height: 30px;
}
.icc-status {
  padding: 5px;
}
.iconx {
  width: 42px;
  height: 42px;
  fill: transparent;
  stroke: black;
  stroke-width: 2px;
  padding: 8px;
  background-color: transparent;
  &-active {
    stroke: white;
    background-color: green;
  }
  &-hidden {
    stroke: white;
    background-color: orange;
  }
  &-flag {
    stroke: initial;
    fill: black;
    border: 2px solid black;
    padding: 0;
    margin: 8px;
  }
}
.actions {
  .btn {
    margin-left: 6px;
  }
}
.table {
  margin-top: 20px;
}
</style>