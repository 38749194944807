import axios from "axios";
import store from "../../store";

export default function (method, url, data = null) {
  return new Promise((resolve, reject) => {
    const API_BASE_URL = store.getters.getPayload.apiUrl;
    const JWT_TOKEN = store.getters.getJwtToken;
    // console.log(API_BASE_URL, 'and', JWT_TOKEN);
    axios({
      baseURL: API_BASE_URL,
      method,
      url,
      data,
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${JWT_TOKEN}`
      }
    }).then(res => {
      // success
      resolve(res.data);
    }).catch(err => {
      // error
      let errData = err;
      if (err.response) {
        if (err.response.status == 401) {
          // router.replace({
          //   name: 'Dashboard'
          // });
          store.commit('putAuthState', false);
        }
        if (err.response.status == 400) {
          errData = err.response.data.data.errors;
        }
      }
      reject(errData);
    });
  });
};